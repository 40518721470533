<template>
  <div class="final-box">
    <div class="final">
      <div class="final-left" :style="isMobile ? 'padding:0 30px' : ''">
        <!-- <div class="final-logo" v-if="!isMobile">
            <img :src="imgList.imgUrl" alt="" />
          </div> -->
        <div class="final-copy">Copyright © 2024 福祺事业</div>
        <div class="final-about" :style="isMobile ? 'flex-wrap: wrap;' : ''">
          <a href="/">首页</a>
          <div class="margin">|</div>
          <a href="/introduce">介绍</a>
          <div class="margin">|</div>
          <a href="/shop">交易</a>
          <div class="margin">|</div>
          <a href="/service">服务中心</a>
          <div class="margin">|</div>
          <a href="/equity">权限专区</a>
          <div class="margin">|</div>
          <a href="/about">关于我们</a>
        </div>
        <div class="final-contact">
          <a href="/about"> 联系我们 </a>
          <div
            class="content-box"
            :style="isMobile ? 'flex-direction:column ;flex-wrap: wrap;' : ''"
          >
            <div>服务时间 09:00-21:00</div>
            <div class="margin" v-if="!isMobile">|</div>
            <div>邮箱 guanfang@odinmeta.cn</div>
          </div>
          <div class="number">
            <a href="https://beian.miit.gov.cn/#/Integrated/index"
              >黔ICP备2024026436号-2</a
            >
          </div>
        </div>

        <div class="link-other">
          <div>友情链接</div>
          <el-link
            href="https://www.mca.gov.cn/"
            target="_blank"
            :underline="false"
            >民政部</el-link
          >
          <el-link
            href="https://www.zgbzxh.org/"
            target="_blank"
            :underline="false"
            >中国殡葬协会</el-link
          >
        </div>
      </div>
      <div class="final-right" v-if="!isMobile">
        <div
          class="final-right-img"
          v-for="(item, index) in eqList"
          :key="index"
        >
          <img :src="item.imgUrl" alt="" />
          <div style="color: #fff; margin: 10px auto; text-align: center">
            {{ item.imgName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PClistImg } from '@/api/img.js'
export default {
  data() {
    return {
      queryParams: {},
      imgList: [],
      eqList: [],
    }
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    this.getImgList()
  },
  methods: {
    getImgList() {
      this.loading = true
      PClistImg(this.queryParams).then((response) => {
        this.imgList = response.rows
        //console.log(this.imgList,"图片列表");
        this.imgList.forEach((item) => {
          if (item.imgPosition == 'logo2') {
            this.imgList = item
            //console.log(this.imgList,"logo2");
          }
          if (item.imgPosition == 'eqcode') {
            this.eqList.push(item)
          }
        })

        this.total = response.total
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.margin {
  margin: 0 10px;
}

.link-other {
  display: flex;
  align-content: flex-start;
  padding-top: 10px;
  color: #fff;
  font-size: 14px;

  ::v-deep .el-link--inner {
    margin-left: 10px;
    text-decoration: underline;
  }

  ::v-deep .el-link--default {
    color: #fff;
  }

  ::v-deep .el-link .el-link--default:hover {
    color: #409eff;
  }
}

.final-box {
  background: #20212a;
  padding: 40px 0;
  .final {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    // height: 308px;

    .final-left {
      width: 600px;
      // height: 208px;

      .final-logo {
        text-align: left;
        img {
          width: 300px;
          height: 26px;
        }
      }
      .final-copy {
        text-align: left;
        // height: 16px;
        // margin-top: 20px;
        color: #ffffff99;
        font-size: 14px;
      }
      .final-about {
        color: #fff;
        display: flex;
        margin-top: 20px;
        font-size: 14px;
      }
      .final-contact {
        text-align: left;
        margin-top: 20px;
        font-size: 16px;
        a {
          text-decoration: none;
          color: #fff;
        }
        .content-box {
          display: flex;
          margin-top: 15px;
          color: #ffffff99;
          font-size: 14px;
        }
        .number {
          margin-top: 10px;
          font-size: 14px;
          color: #ffffff99;
        }
      }
    }
    .final-right {
      display: flex;
      justify-content: center;
      width: 600px;
      height: 208px;
      border-left: 1px solid #fff;
      .final-right-img {
        margin: 20px;
        img {
          width: 130px;
          height: 130px;
        }
        p {
          font-size: 15px;
          text-align: center;
        }
      }
    }
  }
}
</style>

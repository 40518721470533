import request from '@/utils/request'

// 查询陵园列表
export function listCemetery(query) {
  return request({
    url: '/biz/cemetery/list',
    method: 'get',
    params: query
  })
}
export function PClistCemetery(query) {
  return request({
    url: '/biz/cemetery/PClist',
    method: 'get',
    params: query
  })
}

// 查询陵园详细
export function getCemetery(id) {
  return request({
    url: '/biz/cemetery/' + id,
    method: 'get'
  })
}

// 新增陵园
export function addCemetery(data) {
  return request({
    url: '/biz/cemetery',
    method: 'post',
    data: data
  })
}

// 修改陵园
export function updateCemetery(data) {
  return request({
    url: '/biz/cemetery',
    method: 'put',
    data: data
  })
}

// 删除陵园
export function delCemetery(id) {
  return request({
    url: '/biz/cemetery/' + id,
    method: 'delete'
  })
}

<template>
  <div class="shop">
    <!-- <div class="pic"></div> -->

    <zm-swiper :srcList="srcList"></zm-swiper>
    <div class="shop-content" style="">
      <div :style="isMobile ? 'text-align: left;' : 'shop-content-box'">
        <breadCrumb />
      </div>
    </div>
    <div class="shop-box">
      <div
        class="shop-detail"
        :style="isMobile ? 'flex-direction: column;width:90%' : ''"
      >
        <div class="shop-detail-left" style="">
          <img
            :src="cemeteryList.cemeteryImg"
            :style="isMobile ? '' : 'height: 400px'"
          />
        </div>
        <div class="shop-detail-right" :style="isMobile ? 'margin: 0' : ''">
          <div class="right-title" style="">
            <h2>{{ cemeteryList.cemeteryName }}</h2>
          </div>
          <div
            class="right-content"
            v-html="cemeteryList.cemeteryDescribe"
            style=""
          ></div>
          <div class="right-core" style="">
            <span>关键词：</span
            ><span
              v-for="(item, index) in cemeteryList.cemeteryKeyword"
              :key="index"
              >{{ item }}&nbsp; &nbsp;</span
            >
          </div>
          <div class="right-sort" style="">
            <span>所属分类：</span><span>{{ cemeteryList.cemeteryName }}</span>
          </div>
          <div class="right-price" style="">
            <span>售价：</span>
            <span class="display-box-price" style="">{{
              cemeteryList.cemeteryPrice
            }}</span>
            <span class="right-price-unit" style="">万元起/套</span>
          </div>
          <div class="right-phone" style="">
            <span class="right-left" style="">咨询热线:</span
            ><span class="right-right" style="">0851-85516000</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from '@/components/breadCrumb.vue'
import zmSwiper from '@/components/zmSwiperOther.vue'
import { PClistImg } from '@/api/img.js'
import { PClistCemetery } from '@/api/cemetery'
export default {
  components: {
    breadCrumb,
    zmSwiper,
  },
  data() {
    return {
      srcList: [],
      queryParams: {
        id: null,
      },
      cemeteryList: [],
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    if (this.$route.query.id) {
      this.queryParams.id = this.$route.query.id
    }
    this.getList()
    this.getImgList()
  },
  methods: {
    getImgList() {
      const obj = {
        pageNum: 1,
        pageSize: 50,
        imgPosition: 'shopDetail',
      }
      PClistImg(obj).then((response) => {
        response.rows.forEach((item) => {
          if (item.imgPosition == 'shopDetail') {
            this.srcList.push(item.imgUrl)
          }
        })
      })
    },
    /** 查询陵园列表 */
    getList() {
      this.loading = true
      PClistCemetery(this.queryParams).then((response) => {
        this.cemeteryList = response.rows
        this.cemeteryList.forEach((item) => {
          item.cemeteryImg = this.$baseImgUrl + item.cemeteryImg
          item.cemeteryKeyword = JSON.parse(item.cemeteryKeyword).join(',')
          item.cemeteryKeyword = item.cemeteryKeyword.split(',')
        })
        if (!this.queryParams.id) {
          this.cemeteryList = this.cemeteryList[0]
        } else {
          this.cemeteryList.forEach((item) => {
            if (item.id == this.queryParams.id) {
              this.cemeteryList = item
            }
          })
        }
        // console.log(this.cemeteryList,"this.cemeteryList");
        this.total = response.total
        this.loading = false
      })
    },
  },
}
</script>

<style scoped lang="less">
.shop {
  .pic {
    width: 100%;
    height: 500px;
    background-image: url('@/assets/shopbg.jpg');
  }
  .shop-content {
    height: 43px;
    position: relative;
    background-color: rgba(238, 250, 231, 1);
    .shop-content-box {
      position: absolute;
      top: 0;
      left: 350px;
    }
  }
}

.shop-box {
  margin-top: 50px;
  margin-bottom: 50px;
  .shop-detail {
    width: 60%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    .shop-detail-left {
      flex: 1;
    }
    .shop-detail-right {
      flex: 1;
      margin-left: 50px;
      .right-title {
        margin-top: 20px;
        height: 30px;
        border-bottom: 1px solid rgba(92, 197, 102, 1);
        line-height: 30px;
      }
      .right-content {
        margin-top: 20px;
      }
      .right-core {
        margin-top: 20px;
      }
      .right-sort {
        margin-top: 20px;
      }
      .right-price {
        margin-top: 20px;
        .display-box-price {
          font-size: 30px;
          font-style: 500;
          color: #6a381d;
        }
        .right-price-unit {
          color: #6a381d;
        }
      }
      .right-phone {
        margin-top: 30px;
        height: 54px;
        .right-left {
          font-size: 18px;
          color: #444;
          font-weight: bold;
          height: 32px;
        }
        .right-right {
          font-size: 30px;
          color: #26a500;
          height: 54px;
          font-weight: bold;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>

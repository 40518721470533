<template>
  <div class="introduce">
    <!-- <div :class="isMobile ? 'pic-pc' : 'pic'"></div> -->

    <zm-swiper :srcList="srcList"></zm-swiper>

    <div class="nav" :style="isMobile ? 'min-height: 50px;' : ''">
      <div class="nav-box" :style="isMobile ? 'width: 100%;' : 'width: 80%;'">
        <div class="nav-left" style="">
          <breadCrumb></breadCrumb>
        </div>
        <div
          class="nav-right"
          :style="isMobile ? ' height: 50px;line-height: 50px;' : ''"
        >
          自然风水
        </div>
      </div>
    </div>
    <div
      class="introduce-box"
      :style="isMobile ? 'width: 90%;' : 'width: 80%;'"
    >
      <div
        class="introduce-box-content"
        :style="isMobile ? 'flex-direction:column ;' : ''"
      >
        <div :class="isMobile ? 'introduce-box-left-pc' : 'introduce-box-left'">
          <div class="introduce-box-left-box" style="" v-if="!isMobile">
            <div class="introduce-box-left-box-title" style="">
              <div>INTRODUCTION</div>
              <div>介绍</div>
            </div>
          </div>
          <div
            class="display-box-title"
            :style="isMobile ? 'display: flex;flex-wrap: wrap;width: 33%;' : ''"
            v-for="(item, index) in cemeteryList"
            :key="index"
            style=""
          >
            <el-button
              class="display-box-title1"
              :style="isMobile ? 'width: 160px;' : 'width: 255px;'"
              @click="
                $router.push({ path: '/introduce', query: { id: item.id } })
              "
              >{{ item.cemeteryName }}</el-button
            >
          </div>
        </div>
        <div
          class="introduce-box-buttom"
          :style="isMobile ? 'margin-left: 0;' : 'margin-left: 50px;'"
        >
          <el-row :gutter="20">
            <el-col
              :span="isMobile ? 24 : 8"
              v-for="(item, index) in searchList"
              :key="index"
              ><div class="grid-content bg-purple" style="">
                <img
                  @click="goToShopDetail(item.id)"
                  class="img-hover-zoom"
                  :src="item.cemeteryImg"
                  alt=""
                  style=""
                />
                <h2 class="display-box-title" style="">
                  {{ item.cemeteryName }}
                </h2>
                <p
                  v-html="item.cemeteryDescribe"
                  class="display-box-content"
                  style=""
                ></p>
                <div class="display-price">
                  <span>售价：</span>
                  <span class="display-box-price" style="">{{
                    item.cemeteryPrice
                  }}</span>
                  <span style="">万元起/套</span>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from '@/components/breadCrumb.vue'
import zmSwiper from '@/components/zmSwiperOther.vue'
import { PClistCemetery } from '@/api/cemetery'
import { PClistImg } from '@/api/img.js'

export default {
  components: {
    breadCrumb,
    zmSwiper,
  },
  data() {
    return {
      queryParams: {
        id: '',
      },
      cemeteryList: [],
      searchList: [],
      srcList: [],
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    this.queryParams.id = this.$route.query.id
    // console.log(this.queryParams.id, 'this.queryParams.id')
    this.getCemeteryList()
    this.getImgList()
  },
  watch: {
    '$route.query.id': function (newVal) {
      this.searchList = []
      // 当newsKind变化时，更新数据
      this.queryParams.id = newVal
      this.getCemeteryList() // 根据新的newsKind加载数据
    },
  },

  methods: {
    /** 查询陵园列表 */
    getCemeteryList() {
      this.loading = true
      PClistCemetery(this.queryParams).then((response) => {
        this.cemeteryList = response.rows
        if (this.$route.query.id) {
          this.cemeteryList.forEach((item) => {
            if (item.id == this.$route.query.id) {
              item.cemeteryImg = this.$baseImgUrl + item.cemeteryImg
              this.searchList.push(item)
              console.log(this.searchList, 'this.searchList')
            }
          })
        } else {
          this.cemeteryList.forEach((item) => {
            item.cemeteryImg = this.$baseImgUrl + item.cemeteryImg
            this.searchList = this.cemeteryList
            console.log(this.searchList, 'this.searchList')
          })
        }
        this.total = response.total
        this.loading = false
      })
    },
    getImgList() {
      const obj = {
        pageNum: 1,
        pageSize: 50,
        imgPosition: 'introduce',
      }
      PClistImg(obj).then((response) => {
        response.rows.forEach((item) => {
          if (item.imgPosition == 'introduce') {
            this.srcList.push(item.imgUrl)
          }
        })
      })
    },
    goToShopDetail(id) {
      this.$router.push({ path: '/shopDetail', query: { id: id } })
    },
  },
}
</script>

<style scoped>
.introduce-box-left-pc {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>

<style scoped lang="less">
.display-box-content {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示2行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分用省略号表示 */
}

.pic-pc {
  width: 100%;
  height: 300px;
  background: url('@/assets/shopbg.jpg') 100% 100%;
}
.pic {
  width: 100%;
  height: 500px;
  background-image: url('@/assets/introducebg.jpg');
}
.display-box-title :hover {
  color: #9c1524;
}
.grid-content {
  overflow: hidden; /* 隐藏超出容器边界的内容 */
}

.img-hover-zoom {
  transition: transform 0.2s ease-in-out; /* 平滑的过渡效果 */
  width: 100%; /* 图片宽度设为100% */
  height: auto; /* 高度自适应 */
}

.img-hover-zoom:hover {
  transform: scale(1.01); /* 鼠标悬停时放大到1.1倍 */
}
.introduce {
  .nav {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    min-height: 70px;
    background-color: rgba(238, 250, 231, 1);
    .nav-box {
      width: 80%;
      margin: 0 auto;
      position: relative;
    }
    .nav-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .nav-right {
      position: absolute;
      right: 0;
      top: 0;
      height: 70px;
      line-height: 70px;
      padding: 0 10px;
      background-color: #6a381d;
      color: #fff;
      width: 90px;
    }
  }
  .introduce-box {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
    .introduce-box-content {
      width: 100%;
      display: flex;
      .introduce-box-left {
        width: 255px;
        .introduce-box-left-box {
          height: 108px;
          background-color: #6a381d;
          .introduce-box-left-box-title {
            font-size: 24px;
            color: #fff;
            padding: 30px;
          }
        }
        .display-box-title {
          width: 255px;
          text-align: left;
          .display-box-title1 {
            font-size: 16px;
            text-align: left;
            padding: 15px 0 15px 20px;
            border: 1px solid #ccc;
          }
        }
      }
      .introduce-box-buttom {
        flex: 1;
        text-align: left;
        .grid-content {
          margin-top: 30px;
          .img-hover-zoom {
            cursor: pointer;
          }
          .display-box-title {
            margin-top: 10px;
          }
          .display-box-content {
            margin-top: 10px;
            font-size: 12px;
          }
          .display-price {
            .display-box-price {
              color: #6a381d;
              font-size: 30px;
              font-style: 500;
              color: #6a381d;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="about">
    <!-- <div :class="isMobile ? 'pic-pc' : 'pic'"></div> -->

    <zm-swiper :srcList="srcList"></zm-swiper>
    <div class="about-content" :style="isMobile ? '' : 'padding-left:350px'">
      <breadCrumb />
    </div>
    <div class="nav" style="">
      <div class="nav-box" :style="isMobile ? '' : 'width: 50%;'">
        <a class="nav-box-item" href="#introduction1">平台介绍</a>
        <a class="nav-box-item" href="#old1">福光养老</a>
        <a class="nav-box-item" href="#contact1">联系方式</a>
      </div>
    </div>
    <div :class="isMobile ? 'introduction-pc' : 'introduction'" style="">
      <div>
        <div>
          <div
            id="introduction1"
            class="about-title"
            style="margin: 0 auto; text-align: center; margin-top: 20px"
          >
            <h2 class="about-title1" style="font-size: 32px; color: #6a381d">
              平台介绍
            </h2>
          </div>
          <div class="about-title2">INTRODUCTION</div>
        </div>
      </div>
      <div
        class="application-content"
        :style="isMobile ? 'flex-direction: column' : ''"
      >
        <img
          class="application-img"
          :src="introductionList.constantImg"
          :style="
            isMobile ? '' : 'width: 660px;height: 340px;margin-left: 30px'
          "
        />
        <div
          class="application-introduction"
          :style="isMobile ? 'padding: 30px;margin:0' : ''"
        >
          <h2>{{ introductionList.constantTitle }}</h2>

          <p
            class="introduction-content"
            v-html="introductionList.constantDescribe"
          ></p>
        </div>
      </div>
    </div>
    <div class="old">
      <div>
        <div>
          <div id="old1" class="about-title" style="">
            <h2 class="about-title1" style="">福光养老</h2>
          </div>
          <div class="about-title2" style="">OLD</div>
        </div>
      </div>
      <div class="cases-box" :style="isMobile ? 'flex-direction: column' : ''">
        <div class="cases-content" style="">
          <h2 class="cases-title1" style="">{{ oldList.constantTitle }}</h2>
          <!-- <h4 class="cases-title2" style="">建设背景:</h4> -->
          <p
            class="cases-introduction"
            style=""
            v-html="oldList.constantDescribe"
          ></p>
        </div>
        <img
          class="cases-img"
          :style="
            isMobile ? '' : 'width: 880px;height: 560px;margin-left: 30px'
          "
          :src="oldList.constantImg"
          alt=""
          style=""
        />
      </div>
      <div class="message">
        <div>
          <div>
            <div
              id="contact1"
              class="about-title"
              style="margin: 0 auto; text-align: center; margin-top: 20px"
            >
              <h2 class="about-title1" style="font-size: 32px; color: #6a381d">
                联系方式
              </h2>
            </div>
            <div class="about-title2">CONTACT</div>
          </div>
        </div>
        <div>
          <div class="message-content" style="margin-top: 50px">
            <el-form :model="form" ref="formRef" :rules="rules">
              <el-form-item prop="msgUsername">
                <div style="display: flex">
                  <el-input
                    v-model="form.msgUsername"
                    placeholder="您的姓名"
                    class="input-name"
                    style=""
                  ></el-input>
                  <el-input
                    v-model="form.msgUsertel"
                    placeholder="您的电话"
                    class="input-phone"
                    style=""
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="msgContent">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="form.msgContent"
                  class="input-textarea"
                  style=""
                >
                </el-input>
              </el-form-item>
              <div style="" class="message-btn">
                <el-button type="primary" @click="submitForm"
                  >立即提交</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from '@/components/breadCrumb.vue'

import zmSwiper from '@/components/zmSwiperOther.vue'
import { PClistImg } from '@/api/img.js'
import { addMsg } from '@/api/msg'
import { listConstant } from '@/api/constant'

export default {
  components: {
    breadCrumb,
    zmSwiper,
  },
  data() {
    return {
      srcList: [],
      constantList: [],
      introductionList: [],
      oldList: [],
      form: {
        msgUsername: '',
        msgUsertel: '',
        msgContent: '',
      },
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    this.getImgList()
    this.getConstantList()
  },
  methods: {
    getImgList() {
      const obj = {
        pageNum: 1,
        pageSize: 50,
        imgPosition: 'about',
      }
      PClistImg(obj).then((response) => {
        response.rows.forEach((item) => {
          if (item.imgPosition == 'about') {
            this.srcList.push(item.imgUrl)
          }
        })
      })
    },
    getConstantList() {
      this.loading = true
      listConstant(this.queryParams).then((response) => {
        this.constantList = response.rows
        this.constantList.forEach((item) => {
          if (item.constantPosition == 'introduction') {
            this.introductionList = item
          }
          if (item.constantPosition == 'old') {
            this.oldList = item
          }
        })
        //console.log(this.constantList,"关于我们");
      })
    },
    submitForm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          //console.log(this.form);
          if (
            this.form.msgUsername == '' ||
            this.form.msgUsertel == '' ||
            this.form.msgContent == ''
          ) {
            this.$message.error('请填写完整信息')
            return false
          } else if (this.form.msgUsertel.length != 11) {
            this.$message.error('请填写正确的电话号码')
            return false
          }
          this.addMsglist(this.form) // 提交表单数据
          //console.log("表单验证通过", this.form);
          this.$message({
            message: '提交成功',
            type: 'success',
          })
          this.form = { msgUsername: '', msgUsertel: '', msgContent: '' } // 清空表单数据
        } else {
          //console.log("表单验证失败！");
          return false
        }
      })
    },
    addMsglist(data) {
      addMsg(data)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log('添加留言失败', err)
        })
    },
  },
}
</script>

<style scoped lang="less">
.introduction {
  width: 60%;
  margin: 50px auto;
  margin-bottom: 100px;
}
.introduction-pc {
  margin: 50px auto;
  margin-bottom: 100px;
}
</style>

<style scoped lang="less">
.pic {
  width: 100%;
  height: 500px;
  background-image: url('@/assets/aboutbg.jpg');
}
.pic-pc {
  width: 100%;
  height: 300px;
  background: url('@/assets/shopbg.jpg') 100% 100%;
}
.message {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  .message-content {
    margin: 0 auto;
    text-align: center;
    position: relative;
    // .input-name {
    //   width: 40%;
    //   display: inline-block;
    //   position: absolute;
    //   left: 135px;
    //   top: 0;
    // }
    // .input-phone {
    //   width: 40%;
    //   display: inline-block;
    //   position: absolute;
    //   right: 140px;
    //   top: -22px;
    // }
    // .input-textarea {
    //   width: 82%;
    //   margin-top: 30px;
    // }
    .message-btn {
      margin-top: 30px;
      padding-bottom: 50px;
    }
  }
}
.application-content {
  padding-top: 50px;
  display: flex;

  margin: 0 auto;
  text-align: left;
  .application-img {
    flex: 1;

    transition: transform 0.3s ease-in-out;
  }
  .application-img:hover {
    transform: scale(1.02);
    transform-origin: center center;
  }
  .application-introduction {
    flex: 1;
    padding-top: 80px;
    margin-left: 60px;
    color: #333;
    .introduction-active {
      border-bottom: 1px solid skyblue;
      width: 90px;
      height: 30px;
    }
    .introduction-content {
      padding-top: 30px;
      font-size: 14px;
    }
  }
}
.cases-box {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  padding-bottom: 100px;
  .cases-img {
    flex: 1;
    transition: transform 0.3s ease-in-out;
  }
  .cases-content {
    flex: 1;
    margin-top: 80px;
    .cases-title1 {
      color: #333;
    }
    .cases-title2 {
      margin-top: 20px;
      color: #333;
    }
    .cases-introduction {
      margin-top: 20px;
      font-size: 14px;
      color: #333;
    }
    .cases-title3 {
      margin-top: 20px;
      color: #333;
    }
    .cases-introduction2 {
      margin-top: 20px;
      font-size: 14px;
      color: #333;
    }
  }
}
.about-content {
  text-align: left;
  background-color: rgba(238, 250, 231, 1);
}
.nav {
  width: 100%;
  height: 50px;
  margin-top: 50px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 100px;
  .nav-box {
    margin: 0 auto;
    text-align: center;
    display: flex;
    line-height: 50px;
    .nav-box-item {
      flex: 1;
      color: #000;
    }
  }
}
.about-title {
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
}
.about-title1 {
  font-size: 32px;
  color: #6a381d;
}
.about-title2 {
  margin: 0 auto;
  text-align: center;
  margin: 10px auto;
  color: #c1c1c1;
}
</style>

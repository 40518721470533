import request from '@/utils/request'

// 查询常量列表
export function listConstant(query) {
  return request({
    url: '/biz/constant/list',
    method: 'get',
    params: query
  })
}

// 查询常量详细
export function getConstant(id) {
  return request({
    url: '/biz/constant/' + id,
    method: 'get'
  })
}

// 新增常量
export function addConstant(data) {
  return request({
    url: '/biz/constant',
    method: 'post',
    data: data
  })
}

// 修改常量
export function updateConstant(data) {
  return request({
    url: '/biz/constant',
    method: 'put',
    data: data
  })
}

// 删除常量
export function delConstant(id) {
  return request({
    url: '/biz/constant/' + id,
    method: 'delete'
  })
}

<template>
  <div class="system">
    <!-- <div :class="isMobile ? 'pic-pc' : 'pic'"></div> -->

    <zm-swiper :srcList="srcList"></zm-swiper>
    <div
      class="shop-content"
      :style="isMobile ? 'text-align: left;' : 'shop-content-box'"
    >
      <div :class="isMobile ? '' : 'shop-content-box'" style="">
        <breadCrumb />
      </div>
    </div>
    <div style="" class="introduction">
      <div class="introduction-tab">
        <div class="about-title" style="">
          <h2 class="about-title1" style="">权益介绍</h2>
        </div>
        <div class="about-title2" style="">{{ powerList.constantSub }}</div>
      </div>
      <div
        class="bgimg"
        :class="isMobile ? 'bgimg-pc' : ''"
        :style="{ backgroundImage: 'url(' + powerList.constantImg + ')' }"
      >
        <div
          class="introduction-content"
          :style="isMobile ? 'padding-top:30px;margin:0;width:80%' : ''"
        >
          <h2>{{ powerList.constantTitle }}</h2>
          <p
            class="introduction-content-p"
            style=""
            v-html="powerList.constantDescribe"
          ></p>
        </div>
      </div>
    </div>
    <div style="" class="level">
      <div class="introduction-tab">
        <div class="about-title"><h2 class="about-title1">公告公示</h2></div>
        <div class="about-title2">ANNOUNCEMENT</div>
      </div>
      <div class="social-item" style="">
        <el-row :gutter="20" class="social-box" style="">
          <el-col
            :span="isMobile ? 24 : 7"
            v-for="(item, index) in noticeList"
            :key="index"
            class="social-item-box"
            :style="isMobile ? 'margin-left: 0px;' : ''"
            ><div
              class="grid-content bg-purple"
              @click="goToAnnounceDetail(item.id)"
              style=""
            >
              <div class="social-item-title">
                <h3 class="social-item-title1" style="">
                  {{ item.noticeTitle }}
                </h3>
              </div>
              <div class="social-item-time" style="">
                发布时间：{{ item.createTime }}
              </div>
              <div class="social-introduction">
                <p
                  class="social-introduction-p"
                  style=""
                  v-html="item.noticeDescribe"
                ></p>
              </div></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="inherit" style="">
      <div class="introduction-tab">
        <div class="about-title"><h2 class="about-title1">权益继承</h2></div>
        <div class="about-title2">{{ inheritList.constantSub }}</div>
      </div>
      <div class="secure" style="">
        <div :class="isMobile ? 'secure-box-pc' : 'secure-box'" style="">
          <div class="secure-item" style="">
            <img :src="inheritList.constantImg" class="secure-item-img" />
          </div>

          <div class="secure-item2" style="">
            <h2 class="secure-item2-title" style="">
              {{ inheritList.constantTitle }}
            </h2>
            <div class="secure-item2-title2" style="">
              {{ inheritList.constantSub }}
            </div>
            <div
              class="secure-item2-content"
              style=""
              v-html="inheritList.constantDescribe"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from '@/components/breadCrumb.vue'
import zmSwiper from '@/components/zmSwiperOther.vue'
import { PClistImg } from '@/api/img.js'
import { listNotice } from '@/api/notice.js'
import { listConstant } from '@/api/constant.js'
export default {
  components: {
    breadCrumb,
    zmSwiper,
  },
  data() {
    return {
      srcList: [],
      noticeList: [],
      constantList: [],
      powerList: [],
      inheritList: [],
      queryParams: {},
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    this.getList()
    this.getConstantList()
    this.getImgList()
  },
  methods: {
    getImgList() {
      const obj = {
        pageNum: 1,
        pageSize: 50,
        imgPosition: 'equity',
      }
      PClistImg(obj).then((response) => {
        response.rows.forEach((item) => {
          if (item.imgPosition == 'equity') {
            this.srcList.push(item.imgUrl)
          }
        })
      })
    },
    getConstantList() {
      this.loading = true
      listConstant(this.queryParams).then((response) => {
        this.constantList = response.rows
        this.constantList.forEach((item) => {
          if (item.constantPosition == 'power') {
            this.powerList = item
          }
          if (item.constantPosition == 'inherit') {
            this.inheritList = item
          }
        })
        //console.log(this.constantList,"关于我们");
      })
    },
    goToAnnounceDetail(id) {
      console.log('goToJoinDetail', id)
      // 导航到详情页
      this.$router.push(`/announcement/${id}`)
    },
    getList() {
      this.loading = true
      listNotice(this.queryParams).then((response) => {
        this.noticeList = response.rows
        this.noticeList.forEach((item) => {
          item.createTime = item.createTime.substring(0, 10)
        })
        console.log('noticeList', this.noticeList)
        this.total = response.total
        this.loading = false
      })
    },
  },
}
</script>

<style scoped lang="scss">
.pic-pc {
  width: 100%;
  height: 300px;
  background: url('@/assets/shopbg.jpg') 100% 100%;
}

.bgimg-pc {
  display: flex;
  justify-content: center;
}

.secure-box-pc {
  width: 100%;
  flex-direction: column;

  .secure-item2-title {
    margin-top: 30px;
    color: #333;
  }
  .secure-item2-title2 {
    font-size: 12px;
    color: #ccc;
    margin-top: 10px;
  }
  .secure-item2-content {
    font-size: 14px;
    color: #333;
    text-align: left;
    padding: 20px;
  }
}
</style>

<style scoped lang="less">
.system {
  width: 100%;
  background-color: #fff;
  .pic {
    width: 100%;
    height: 500px;
    background-image: url(@/assets/equitybg.jpg);
  }
  .shop-content {
    height: 43px;
    position: relative;
    background-color: rgba(238, 250, 231, 1);
    .shop-content-box {
      position: absolute;
      top: 0;
      left: 350px;
    }
  }
  .introduction {
    width: 100%;
    margin: 50px auto;
    text-align: left;
    .introduction-tab {
      .about-title {
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        .about-title1 {
          font-size: 32px;
          color: #6a381d;
        }
      }
      .about-title2 {
        margin: 0 auto;
        text-align: center;
        margin: 10px auto;
        color: #c1c1c1;
      }
    }
    .bgimg {
      //background-image: url("@/assets/system1.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 350px;
      width: 100%;
      .introduction-content {
        width: 60%;
        margin: 0px 0 30px 50px;
        padding-top: 80px;
      }
      .introduction-content-p {
        margin-top: 20px;
      }
    }
  }
  .level {
    width: 100%;
    margin: 50px auto;
    text-align: left;
    .introduction-tab {
      .about-title {
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        .about-title1 {
          font-size: 32px;
          color: #6a381d;
        }
      }
      .about-title2 {
        margin: 0 auto;
        text-align: center;
        margin: 10px auto;
        color: #c1c1c1;
      }
    }
  }
  .social-item {
    margin: 50px auto;
    text-align: left;
    width: 80%;
    margin-top: 20px;

    .social-box {
      margin-left: 100px;
      .social-item-box {
        background-color: #f9f9f9;
        margin-left: 20px;
        margin-top: 20px;
        .grid-content {
          cursor: pointer;
          .social-item-title {
            .social-item-title1 {
              margin: 20px;
              font-weight: bold;
            }
          }
          .social-item-time {
            margin-left: 20px;
            font-size: 14px;
          }
          .social-introduction {
            font-size: 14px;
            margin-top: 10px;
            height: 170px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .social-introduction-p {
              margin-top: 5px;
              margin-left: -8px;
            }
          }
        }
      }
    }
  }
  .inherit {
    padding-bottom: 100px;
    margin-top: 100px;
    .introduction-tab {
      .about-title {
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        .about-title1 {
          font-size: 32px;
          color: #6a381d;
        }
      }
      .about-title2 {
        margin: 0 auto;
        text-align: center;
        margin: 10px auto;
        color: #c1c1c1;
      }
    }
    .secure {
      background-color: #f5f8fa;
      .secure-box {
        width: 80%;
        margin: 0 auto;
        text-align: left;
        display: flex;
        .secure-item {
          flex: 1;
          .secure-item-img {
            width: 574px;
            height: 363px;
          }
        }
        .secure-item2 {
          flex: 1;
          margin-left: -200px;
          .secure-item2-title {
            margin-top: 60px;
            color: #333;
          }
          .secure-item2-title2 {
            font-size: 12px;
            color: #ccc;
            margin-top: 10px;
          }
          .secure-item2-content {
            margin-top: 30px;
            font-size: 14px;
            color: #333;
          }
        }
      }
    }
  }
}
.title-active {
  border-bottom: 1px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}

.break {
  width: 75%;
  text-align: left;
  margin: 30px auto;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #333;
}
.title::before {
  content: '';
  background-image: url('@/assets/before.png');
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-right: 20px;
}
.title::after {
  content: '';
  background-image: url('@/assets/after.png');
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-left: 20px;
}
.title-eng {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #999;
}

.level {
  width: 100%;
  margin: 50px auto;
  text-align: left;
  .level-title {
    width: 100%;
    margin: 50px auto;
    text-align: center;
    color: #333;
    font-size: 20px;
  }
  .level-content {
    width: 100%;
    margin: 50px auto;
    text-align: center;
    display: flex;
    .level-item {
      flex: 1;
      background-color: #f9f9f9;
      margin-left: 10px;
      height: 400px;
      .level-item-img {
        padding-top: 100px;
        width: 100px;
        height: 100px;
      }
      .level-item-title {
        margin-top: 30px;
        color: #333;
      }
      .level-item-li {
        margin-top: 20px;
        color: #333;
      }
    }
  }
}

.operation {
  background-color: #f5f8fa;
  .operation-box {
    width: 80%;
    margin: 50px auto;
    text-align: left;
    display: flex;
    .opration-item {
      flex: 1;
      .operation-item-title {
        margin-top: 60px;
        color: #333;
      }
      .operation-item-title2 {
        font-size: 12px;
        color: #ccc;
        margin-top: 10px;
      }
      .operation-content {
        margin-top: 30px;
        font-size: 14px;
        color: #333;
      }
    }
    .operation-btn {
      width: 100%;
      margin: 50px auto;
      text-align: left;
      .operation-btm-item {
        margin-top: 20px;
      }
    }
    .operation-item2 {
      flex: 1;
      .operation-item2-img {
        width: 574px;
        height: 363px;
      }
    }
  }
}
p {
  line-height: 2.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
}
</style>

/*
 * @Description: 
 * @Date: 2024-04-26 10:03:25
 * @LastEditTime: 2024-04-26 10:08:49
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false, // 默认为非移动设备
  },
  mutations: {
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
  },
  actions: {
    detectDevice({ commit }) {
      // 检测设备类型
      const userAgent = navigator.userAgent;
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      commit('setIsMobile', isMobile); // 将结果保存到 Vuex 中
    },
  },
});


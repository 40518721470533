<!--
 * @Description: 
 * @Date: 2024-04-26 10:03:25
 * @LastEditTime: 2024-04-28 09:42:54
-->
<template>
  <div id="app">
    <tabbar class="fixed-top"></tabbar>
    <router-view class="content" />
    <final></final>
    <el-backtop :bottom="100">
      <div class="backTop">
        <i class="el-icon-upload2"></i>
      </div>
    </el-backtop>
  </div>
</template>
<script>
import final from '@/components/final.vue'
import tabbar from '@/components/tabbar.vue'
export default {
  components: {
    final,
    tabbar,
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fixed-top {
  position: fixed; /* 固定位置 */
  top: 0; /* 距离顶部0像素 */
  width: 100%; /* 导航栏宽度 */
  z-index: 1000; /* 确保导航栏在最上层 */
  padding-bottom: 60px;
}

.content {
  padding-top: 60px !important;
}

.backTop {
  color: #2c3e50;
  font-weight: bold;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

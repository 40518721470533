import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home.vue'
import Introduce from '@/views/introduce/Introduce.vue'
import Shop from '@/views/shop/Shopping.vue'
import ShopDetail from '@/views/shop/ShopDetail.vue'

import Service from '@/views/service/Service.vue'
import Equity from '@/views/equity/Equity.vue'
import About from '@/views/about/About.vue'
// import Display from '@/views/display/Display.vue'
import Announcement from '@/views/announcement/Announcement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{
      title:'首页'
    }
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: Introduce,
    props: true, // 启用路由参数解构传递
    meta:{
      title:'介绍'
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop,
    meta:{
      title:'交易'
    }
  },
  {
    path: '/service',
    name: 'service',
    component: Service,
    //props: true, // 启用路由参数解构传递
    meta:{
      title:'服务中心'
    }
  },

  {
    path: '/equity',
    name: 'equity',
    component: Equity,
    meta:{
      title:'股权'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta:{
      title:'关于我们'
    }
  },

  {
    path: '/shopdetail',
    name: 'shopdetail',
    component: ShopDetail,
    meta:{
      title:'详情'
    }
  },
  {
    path: '/announcement/:id',
    name: 'announcement',
    component: Announcement,
    meta:{
      title:'公告'
    }
  }

  // {
  //   path: '/display',
  //   name: 'display',
  //   component: Display
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

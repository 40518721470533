/*
 * @Description:
 * @Date: 2023-09-26 20:59:48
 * @LastEditTime: 2024-07-24 18:47:45
 */
import axios from 'axios'
import {
  //MessageBox,
  Message,
} from 'element-ui'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: 'https://www.fuguang.org.cn/api', // ptUI
  timeout: 5000,
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code !== 200) {
      if (res.code == 440) {
        Message({
          message: '身份过期，请重新登录',
          type: 'error',
          duration: 2 * 1000,
        })
        // to re-login
        location.reload()
      } else {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000,
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service

<!--
 * @Description: 
 * @Date: 2024-04-26 10:03:25
 * @LastEditTime: 2024-04-28 11:30:58
-->
<template>
  <div class="shop">
    <!-- <div :class="isMobile ? 'pic-pc' : 'pic'"></div> -->

    <zm-swiper :srcList="srcList"></zm-swiper>

    <div
      class="shop-content"
      :style="isMobile ? 'text-align: left;' : 'shop-content-box'"
    >
      <div :class="isMobile ? '' : 'shop-content-box'">
        <breadCrumb />
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from '@/components/breadCrumb.vue'
import zmSwiper from '@/components/zmSwiperOther.vue'
import { PClistImg } from '@/api/img.js'
export default {
  components: {
    breadCrumb,
    zmSwiper,
  },
  data() {
    return {
      srcList: [],
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    this.getImgList()
  },
  methods: {
    getImgList() {
      const obj = {
        pageNum: 1,
        pageSize: 50,
        imgPosition: 'shop',
      }
      PClistImg(obj).then((response) => {
        response.rows.forEach((item) => {
          if (item.imgPosition == 'shop') {
            this.srcList.push(item.imgUrl)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.shop {
  .pic {
    width: 100%;
    height: 500px;
    background-image: url('@/assets/shopbg.jpg');
  }

  .pic-pc {
    width: 100%;
    height: 300px;
    background: url('@/assets/shopbg.jpg') 100% 100%;
  }
  .shop-content {
    height: 43px;
    position: relative;
    background-color: rgba(238, 250, 231, 1);
    .shop-content-box {
      position: absolute;
      top: 0;
      left: 350px;
    }
  }
}
</style>

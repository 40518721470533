<template>
  <div class="introduce">
    <!-- <div :class="isMobile ? 'pic-pc' : 'pic'"></div> -->

    <zm-swiper :srcList="srcList"></zm-swiper>

    <div class="nav" style="">
      <div :class="isMobile ? 'nav-box-pc' : 'nav-box'">
        <div class="nav-left" style="">
          <breadCrumb></breadCrumb>
        </div>
        <div class="nav-right-item" style="">
          <div
            class="nav-item"
            v-for="(item, index) in serviceList"
            :key="index"
            @click="goDetail(item.id)"
            :class="{ highlight: currentHighlight === item.id }"
          >
            {{ item.serviceName }}
          </div>
        </div>
      </div>
    </div>
    <div class="content" :style="isMobile ? 'width: 90%;' : 'width: 80%;'">
      <div class="content-title" style="">
        <h2>{{ contentList.serviceName }}</h2>
      </div>
      <div class="content-text">
        <p v-html="contentList.serviceContent"></p>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from '@/components/breadCrumb.vue'
import zmSwiper from '@/components/zmSwiperOther.vue'

import { PClistService } from '@/api/service.js'
import { PClistImg } from '@/api/img.js'
export default {
  components: {
    breadCrumb,
    zmSwiper,
  },
  data() {
    return {
      srcList: [],
      serviceList: [],
      queryParams: {},
      contentList: {},
      currentHighlight: null, // 初始时没有高亮显示的项 1
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    if (this.$route.query.id) {
      this.queryParams.id = this.$route.query.id
    }

    this.getList()
    this.getImgList()
  },
  methods: {
    getImgList() {
      const obj = {
        pageNum: 1,
        pageSize: 50,
        imgPosition: 'introduce',
      }
      PClistImg(obj).then((response) => {
        response.rows.forEach((item) => {
          if (item.imgPosition == 'introduce') {
            this.srcList.push(item.imgUrl)
          }
        })
      })
    },
    /** 查询服务列表 */
    getList() {
      this.loading = true
      PClistService(this.queryParams).then((response) => {
        this.serviceList = response.rows
        // console.log(this.serviceList, '服务列表')

        if (!this.queryParams.id) {
          this.contentList = this.serviceList[0]
          this.currentHighlight = this.serviceList[0].id
          //console.log(this.queryParams.id,"!id");
        } else {
          //console.log(this.queryParams.id,"id");
          this.serviceList.forEach((item) => {
            if (item.id == this.queryParams.id) {
              this.contentList = item
              // console.log(this.contentList, '服务')
            }
          })
        }

        this.total = response.total
        this.loading = false
      })
    },
    goDetail(id) {
      this.queryParams.id = id
      // 高亮2
      this.currentHighlight = id
      this.$router
        .push({ path: '/service', query: { id: id } })
        .catch((err) => {
          // 忽略重复导航的错误
          if (err.name !== 'NavigationDuplicated') {
            // 处理其他错误
            throw err
          }
        })

      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ql-align-justify {
  text-indent: 0 !important;
}

.pic-pc {
  width: 100%;
  height: 300px;
  background: url('@/assets/shopbg.jpg') 100% 100%;
}

.nav-box-pc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  .nav-left {
    border-bottom: 1px solid #ddd;
  }

  .nav-right-item {
    display: flex;
    flex-wrap: wrap;
    // overflow: scroll;
    text-align: center;
    padding: 0 10px;
    color: #fff;

    .nav-item {
      white-space: nowrap;
      padding: 0 10px;
      color: #000;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>

<style scoped lang="scss">
.introduce {
  .pic {
    width: 100%;
    height: 500px;
    background-image: url('@/assets/servicebg.jpg');
    background-size: cover;
    background-position: center;
  }

  .nav {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: rgba(238, 250, 231, 1);
    .nav-box {
      margin: 0 auto;
      width: 80%;
      display: flex;
      justify-content: space-between;

      .nav-right-item {
        display: flex;
        height: 70px;
        line-height: 70px;
        padding: 0 10px;
        color: #fff;
        width: 540px;
        .nav-item {
          flex: 1;
          color: #000;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    .content-title {
      height: 30px;
      border-bottom: 1px solid #ccc;
    }
    .content-text {
      text-align: left;
      margin-top: 20px;
    }
  }
}

.nav-item:hover {
  background-color: #6a381d;
  color: #fff !important;
}
//高亮4
.highlight {
  background-color: #6a381d;
  color: #fff !important;
}
</style>

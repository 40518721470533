<template>
  <div class="tabbar-box">
    <div class="tabbar" v-if="!isMobile">
      <el-menu
        :router="true"
        :default-active="activeIndex2"
        mode="horizontal"
        @select="handleSelect"
        background-color="#f2f5ee"
        text-color="#171a20"
        active-text-color="#171a20"
      >
        <div class="logo-box">
          <img :src="imgList.imgUrl" alt="" />
        </div>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/introduce">介绍</el-menu-item>
        <el-menu-item index="/shop">交易</el-menu-item>
        <el-menu-item index="/service">服务中心</el-menu-item>
        <el-menu-item index="/equity">权益专区</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <span class="el-icon-bell icon"></span>
      </el-menu>
    </div>

    <div class="collapse" v-if="isMobile">
      <div class="logo-box">
        <img :src="imgList.imgUrl" alt="" />
      </div>
      <el-menu
        :router="true"
        :default-active="activeIndex2"
        @select="handleSelect"
        mode="horizontal"
        background-color="#f2f5ee"
        text-color="#171a20"
      >
        <el-submenu>
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/introduce">介绍</el-menu-item>
          <el-menu-item index="/shop">交易</el-menu-item>
          <el-menu-item index="/service">服务中心</el-menu-item>
          <el-menu-item index="/equity">权益专区</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { PClistImg } from '@/api/img.js'
export default {
  name: 'tabbar',
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '/',
      queryParams: {},
      imgList: [],
      // 存储用户选择的导航项
      isCollapse: false,
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    console.log(this.isMobile, 'isMobile')
    this.getImgList()
    // 使用 Vue Router 的全局前置守卫来监听路由变化
    this.$router.beforeEach((to, from, next) => {
      // 在路由变化之前更新 activeIndex2
      this.activeIndex2 = to.path
      // console.log(this.activeIndex2,"activeIndex2");

      next()
    })
  },
  mounted() {
    const storedActiveIndex = localStorage.getItem('activeIndex')
    if (storedActiveIndex) {
      this.activeIndex2 = storedActiveIndex
    }
  },

  beforeDestroy() {
    // 页面卸载时将高亮索引存储到 localStorage
    localStorage.setItem('activeIndex', this.activeIndex2)
  },
  methods: {
    handleSelect(index) {
      // 当用户选择新的导航项时，更新 activeIndex2 并存储到 localStorage
      this.activeIndex2 = index
      localStorage.setItem('activeIndex', this.activeIndex2)
    },
    getImgList() {
      this.loading = true
      PClistImg(this.queryParams).then((response) => {
        this.imgList = response.rows
        //console.log(this.imgList,"图片列表");
        this.imgList.forEach((item) => {
          if (item.imgPosition == 'logo1') {
            this.imgList = item
            console.log(this.imgList, 'logo1')
          }
        })
        this.total = response.total
        this.loading = false
      })
    },
  },
}
</script>

<style lang="less" scoped>
.icon {
  display: flex;
  font-size: 25px;
  color: #000;
  font-weight: bold;
  align-items: center;
  margin: 0 40px;
}
</style>

<style lang="less" scoped>
.tabbar {
  .el-menu {
    display: flex;
    justify-content: center;
  }

  /deep/ .el-menu-item {
    text-align: center;
    width: 150px;
    padding: 10px;
    height: 90px;
    line-height: 70px;
    font-size: 20px;
    font-weight: bold;
  }

  /deep/ .el-menu-item.is-active {
    border-bottom: 4px solid #171a20;
  }

  .logo-box {
    display: flex;
    align-items: center;
    margin: 0 40px;
    img {
      height: 50px;
      width: 170px;
    }
  }
}
</style>
<style lang="less" scoped>
.collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f5ee;

  .logo-box {
    display: flex;
    align-items: center;
    margin: 0 20px;
    width: 150px;
    img {
      height: 25px;
      width: 100px;
    }
  }

  /deep/ .el-menu-item {
    text-align: left;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
  }

  /deep/ .el-menu--horizontal {
    flex-grow: 1;
  }

  /deep/ .el-submenu {
    width: 100%;
  }

  /deep/ .el-submenu__title {
    text-align: right;
  }

  /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    // border-bottom: 4px solid #fff;
    border-bottom-color: transparent;
  }
}
</style>

<template>
  <div class="home">
    <div>
      <zm-swiper :srcList="srcList"></zm-swiper>
    </div>
    <div class="about" style="">
      <div :class="isMobile ? 'about-box-phone' : 'about-box'" style="">
        <div class="about-left" style="">
          <img :src="constantList.constantImg" alt="" />
        </div>
        <div :class="isMobile ? 'about-right-phone' : 'about-right'" style="">
          <div class="about-title" style="">
            <h2>{{ constantList.constantTitle }}</h2>
          </div>
          <div class="about-title2" style="">
            {{ constantList.constantSub }}
          </div>
          <div><p v-html="constantList.constantDescribe"></p></div>
          <div class="about-buttom" style="">
            <div class="about-buttom-item" style="">
              <span class="about-buttom-item-span" style="">7</span
              ><span>天</span>
              <div class="about-buttom-item-div" style="">无理由退货</div>
            </div>
            <div class="about-buttom-item">
              <span class="about-buttom-item-span">20</span><span>分钟</span>
              <div class="about-buttom-item-div">距离主城区</div>
            </div>
            <div class="about-buttom-item">
              <span class="about-buttom-item-span">30</span><span>天</span>
              <div class="about-buttom-item-div">免费骨灰寄存</div>
            </div>
            <div class="about-buttom-item">
              <span class="about-buttom-item-span">49</span><span>天</span>
              <div class="about-buttom-item-div">烧七服务</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="display" style="">
      <div class="display-tab">
        <div class="about-title" style="">
          <h2 class="about-title1" style="">展示</h2>
        </div>
        <div class="about-title2" style="">DISPLAY</div>
      </div>
      <div class="display-box" style="">
        <el-row :gutter="20">
          <el-col
            :span="isMobile ? 24 : 8"
            v-for="(item, index) in cemeteryList"
            :key="index"
          >
            <div class="grid-content bg-purple" style="">
              <img
                @click="goToShopDetail(item.id)"
                :src="item.cemeteryImg"
                alt=""
                class="img-hover-zoom"
                style=""
              />
              <!-- 添加 img-hover-zoom 类 -->
              <h2 class="display-box-title" style="">
                {{ item.cemeteryName }}
              </h2>
              <p
                v-html="item.cemeteryDescribe"
                class="display-box-content"
                style=""
              ></p>
              <div class="display-price">
                <span>售价：</span>
                <span class="display-box-price" style="">{{
                  item.cemeteryPrice
                }}</span>
                <span style="">万元起/套</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="service" style="">
      <div class="service-tab">
        <div class="service-title" style="">SERVICE ITEM</div>
        <h2 class="service-title2" style="">服务项目</h2>
      </div>
      <div class="service-box" style="">
        <el-row :gutter="50">
          <el-col
            :span="isMobile ? 24 : 8"
            v-for="(item, index) in serviceList"
            :key="index"
            ><div class="grid-content bg-purple" style="">
              <img
                @click="goService(item.id)"
                :src="item.serviceImg"
                alt=""
                class="service-img"
                style=""
              />
              <div class="service-div" style="">{{ item.serviceName }}</div>
              <!-- <div>-></div> -->
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import zmSwiper from '@/components/zmSwiper.vue'
import { listConstant } from '@/api/constant'

import { PClistService } from '@/api/service.js'
import { PClistCemetery } from '@/api/cemetery'
import { PClistImg } from '@/api/img.js'

export default {
  components: {
    zmSwiper,
  },
  data() {
    return {
      imgList: [],
      srcList: [],
      serviceList: [],
      cemeteryList: [],
      constantList: [],
      queryParams: {
        imgPosition: '',
        // pageNum: 1,
        // pageSize: 2,
      },
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    this.getList()
    this.getCemeteryList()
    this.getImgList()
    this.getConstantList()
  },
  methods: {
    getConstantList() {
      this.loading = true
      listConstant(this.queryParams).then((response) => {
        this.constantList = response.rows
        this.constantList.forEach((item) => {
          if (item.constantTitle == '关于我们') {
            this.constantList = item
          }
        })
        console.log(this.constantList, '关于我们')
      })
    },
    /** 查询服务列表 */
    getList() {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 6
      this.loading = true
      console.log('this.queryParams', this.queryParams)
      PClistService(this.queryParams).then((response) => {
        response.rows.forEach((item) => {
          item.createTime = item.createTime.substr(0, 10)
          item.serviceImg = this.$baseImgUrl + item.serviceImg
        })
        this.serviceList = response.rows

        console.log(this.serviceList, '服务列表')
        this.total = response.total
        this.loading = false
      })
    },
    getImgList() {
      this.loading = true
      PClistImg(this.queryParams).then((response) => {
        this.imgList = response.rows
        //console.log(this.imgList,"图片列表");
        this.imgList.forEach((item) => {
          if (item.imgPosition == 'index') {
            this.srcList.push(item.imgUrl)
            console.log(this.srcList, 'this.srcList')
          }
        })
        this.total = response.total
        this.loading = false
      })
    },
    goService(id) {
      // console.log(id,"id");
      this.$router.push({ path: '/service', query: { id: id } })
    },
    goToShopDetail(id) {
      this.$router.push({ path: '/shopDetail', query: { id: id } })
    },
    /** 查询陵园列表 */
    getCemeteryList() {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 9
      this.loading = true
      PClistCemetery(this.queryParams).then((response) => {
        this.cemeteryList = response.rows
        this.cemeteryList.forEach((item) => {
          item.cemeteryImg = this.$baseImgUrl + item.cemeteryImg
        })
        //console.log(this.cemeteryList,"this.cemeteryList");
        this.total = response.total
        this.loading = false
      })
    },
  },
}
</script>

<style scoped lang="scss">
.about-box-phone {
  .about-right-phone {
    margin: 30px;
  }
}

.about-buttom {
  width: 100%;
  display: flex;
  margin-top: 50px;
  height: 100px;
  border-bottom: 1px solid #ccc;
  .about-buttom-item {
    flex: 1;
    text-align: center;
    color: #6a381d;
    .about-buttom-item-span {
      font-size: 36px;
      color: #6a381d;
      font-weight: bold;
      font-family: Impact;
    }
    .about-buttom-item-div {
      font-size: 13px;
      color: #8e8e8e;
    }
  }
}
</style>

<style scoped lang="scss">
.display-box-content {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示2行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分用省略号表示 */
}

.service-img {
  width: 412px;
  cursor: pointer;
  height: 223px;
}
.grid-content {
  overflow: hidden; /* 隐藏超出容器边界的内容 */
}

.img-hover-zoom {
  transition: transform 0.2s ease-in-out; /* 平滑的过渡效果 */
  width: 100%; /* 图片宽度设为100% */
  height: auto; /* 高度自适应 */
}

.img-hover-zoom:hover {
  transform: scale(1.01); /* 鼠标悬停时放大到1.1倍 */
}
.home {
  .about {
    width: 100%;
    // height: 700px;
    margin-top: 50px;
    .about-box {
      width: 80%;
      text-align: left;
      margin: 0 auto;
      display: flex;
      .about-left {
        width: 796px;
        height: 442px;
      }
      .about-right {
        flex: 1;
        margin-left: 30px;
        .about-title {
          margin: 0 auto;
          text-align: center;
          margin-top: 20px;
          color: #6a381d;
        }
        .about-title2 {
          margin: 0 auto;
          text-align: center;
          margin: 10px auto;
          color: #c1c1c1;
        }
      }
    }
  }
  .display {
    width: 100%;
    background-color: #f2f5ee;
    padding-bottom: 30px;
    .display-tab {
      .about-title {
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        padding-top: 30px;
        .about-title1 {
          font-size: 32px;
          color: #6a381d;
        }
      }
      .about-title2 {
        margin: 0 auto;
        text-align: center;
        margin: 10px auto;
        color: #c1c1c1;
      }
    }
    .display-box {
      width: 80%;
      text-align: left;
      margin: 0 auto;
      .grid-content {
        margin-top: 30px;
        .img-hover-zoom {
          cursor: pointer;
        }
        .display-box-title {
          margin-top: 10px;
        }
        .display-box-content {
          margin-top: 10px;
          font-size: 12px;
        }
        .display-price {
          color: #6a381d;
          .display-box-price {
            font-size: 30px;
            font-weight: 500;
            color: #6a381d;
          }
        }
      }
    }
  }
  .service {
    width: 100%;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
    .service-tab {
      .service-title {
        font-size: 50px;
        color: #979797;
      }
      .service-title2 {
        margin-top: 20px;
        color: #6a381d;
      }
    }
    .service-box {
      width: 80%;
      text-align: center;
      margin: 0 auto;
      margin-top: 30px;

      .grid-content {
        margin-top: 30px;
      }
      .service-div {
        margin-top: 10px;
      }
    }
  }
}
</style>

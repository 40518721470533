/*
 * @Description: 
 * @Date: 2024-04-26 10:03:25
 * @LastEditTime: 2024-04-26 18:11:25
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import zmSwiper from '@/components/zmSwiper'
import breadCrumb from '@/components/breadCrumb.vue'
import pagination from '@/components/pagination.vue'

// Vue.prototype.$baseImgUrl='http://192.168.2.250:81/dev-api'
Vue.prototype.$baseImgUrl=''

Vue.component('zmSwiper', zmSwiper)
Vue.component('breadCrumb', breadCrumb)
Vue.component('pagination', pagination)
Vue.config.productionTip = false
Vue.use(ElementUI);


new Vue({
  router,
  store,
  created(){
    this.$store.dispatch('detectDevice')
  },
  render: h => h(App)
}).$mount('#app')

<!--
 * @Description: 
 * @Date: 2024-04-26 10:03:25
 * @LastEditTime: 2024-04-26 14:23:16
-->
<template>
  <el-carousel trigger="click" :height="isMobile ? '' : '800px'" arrow="never">
    <el-carousel-item v-for="(item, idx) in srcList" :key="idx">
      <img :src="item" class="img" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import swImg1 from '@/assets/轮播1.png'
import swImg2 from '@/assets/轮播1.png'
export default {
  props: {
    srcList: {
      type: Array,
      // required: true,
      default: () => [{ src: swImg1 }, { src: swImg2 }],
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
  },
  created() {
    // console.log('created', document.querySelector('.imgssss'))
    // const cimte = setTimeout(() => {
    //     console.log('setTimeout')
    //     clearTimeout(cimte)
    // }, 3000)
    // let cout = 0
    // const timr = setInterval(() => {
    //     cout++
    //     if(cout == 10) {
    //         clearInterval(timr)
    //     }
    // }, 1000)
  },
  beforeMount() {},
  mounted() {
    console.log('mounted', document.querySelector('.imgssss'))
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
}
</script>

<style>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

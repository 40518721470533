<template>
  <div>
    <div style="" class="newsdetail">
      <div style="" class="newsdetail-box">
        <!-- 左边 -->
        <div class="newsdetail-left" style="">
          <!-- 头部标题 -->
          <div class="left-header" style="">
            <h2 class="header-title" style="">{{ noticeList.noticeTitle }}</h2>
            <div class="header-time" style="">
              {{ noticeList.createTime }}&nbsp;&nbsp;&nbsp;浏览{{
                noticeList.noticeCount
              }}次
            </div>
          </div>
          <div v-html="noticeList.noticeDescribe" style="" class="p"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//   import { getJob } from "@/api/job";
import { getNotice } from '@/api/notice'

export default {
  data() {
    return {
      queryParams: {
        id: this.$route.params.id,
      },
      noticeList: [],
    }
  },
  created() {
    this.getList()
    console.log(this.$route.params.id, 'this.$route.query.id')
  },
  methods: {
    //       getList() {
    //     this.loading = true;
    //     listNotice(this.queryParams.id).then((response) => {
    //       this.noticeList = response.data;
    //      this.noticeList.createTime = this.noticeList.createTime.substring(0,10);
    //      // console.log(this.jobList,'this.jobList')
    //       this.loading = false;
    //     });
    //   },
    getList() {
      this.loading = true
      getNotice(this.queryParams.id).then((response) => {
        this.noticeList = response.data
        this.noticeList.createTime = this.noticeList.createTime.substring(0, 10)
        console.log(this.noticeList, 'this.noticeList')
        this.total = response.total
        this.loading = false
      })
    },
  },
}
</script>

<style scoped lang="less">
.p {
  color: #333;
  line-height: 2.5; /* 调整行间距 */
  font-size: 14px;
  margin-bottom: 10px; /* 可选：段落间距 */
  padding-top: 20px;
  color: #333;
  padding-top: 30px;
  text-align: left;
  margin-left: -30px;
}
.newsdetail {
  width: 100%;
  background-color: #fff;
  .newsdetail-box {
    width: 80%;
    margin: 0 auto;
    display: flex;
    padding-top: 50px;
    height: 100%;
    padding-bottom: 100px;
    .newsdetail-left {
      flex: 2;
      .left-header {
        height: 100px;
        border-bottom: 1px solid #000;
        .header-title {
          color: #333;
          text-align: left;
          font-weight: bold;
        }
        .header-time {
          color: #333;
          margin-top: 20px;
          text-align: left;
        }
      }

      .content-box {
        width: 100%;
        height: 400px;
        margin: 0 auto;
        text-align: center;
        margin-top: 30px;
        .content-img {
          height: 400px;
          margin: 0 auto;
        }
      }
      .introduction {
        color: #333;
        margin: 0 auto;
        text-align: center;
        padding-top: 20px;
        font-size: 14px;
      }
    }
    .newsdetail-right {
      flex: 1;
      width: 100%;
      padding-left: 100px;
      padding-top: 60px;
      .right-header {
        width: 60%;
        .right-title {
          color: #333;
        }
        .right-box {
          display: flex;
          height: 90px;
          margin: 0 auto;
          margin-top: 20px;
          .right-box-left {
            flex: 1;
            .right-box-img {
              height: 90px;
            }
          }
          .right-box-right {
            flex: 2;
            margin-left: 10px;
            .right-box-right-title {
              color: #333;
              font-size: 14px;
            }
            .right-box-right-time {
              color: #333;
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
.content {
  color: #333;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
}
</style>
